<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="7" lg="4">
        <v-card outlined>
          <v-img v-if="gym.srcMainImpression" :src="gym.srcMainImpression" />
          <v-list-item class="pt-2">
            <v-list-item-avatar>
              <v-img :src="gym.srcIcon" />
            </v-list-item-avatar>
            <v-list-item-title class="headline">{{ gym.name }}</v-list-item-title>
            <v-list-item-action>
              <v-btn icon @click="switchGym"><v-icon>tl-swap-horiz</v-icon></v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-card-text v-if="gym.description">{{ gym.description }}</v-card-text>
          <v-divider />
          <v-row class="px-4">
            <v-col v-if="gym.nr_of_routes" class="grade-distribution">
              <div class="font-weight-bold mb-4" flex v-t="'generic.routes'" />
              <tl-grade-dist climb-type="route" :counts="gym.grade_distribution_routes" />
              <v-row class="font-weight-bold justify-space-between">
                <v-col v-t="'generic.total'" />
                <v-col class="text-right">{{ gym.nr_of_routes }}</v-col>
              </v-row>
            </v-col>

            <v-divider v-if="gym.nr_of_routes && gym.nr_of_boulders" vertical />

            <v-col v-if="gym.nr_of_boulders" class="grade-distribution">
              <div class="font-weight-bold mb-4" flex v-t="'generic.boulders'" />
              <tl-grade-dist climb-type="boulder" :counts="gym.grade_distribution_boulders" />
              <v-row class="font-weight-bold">
                <v-col v-t="'generic.total'" />
                <v-col class="text-right">{{ gym.nr_of_boulders }}</v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider />
          <v-card-actions class="justify-space-around">
            <v-btn v-if="gym.url_website" text :href="gym.url_website" target="_blank">
              <v-icon left>tl-language</v-icon>
              {{ $t('gym.website') }}
            </v-btn>
            <v-btn v-if="gym.url_facebook" text :href="gym.url_facebook" target="_blank">
              <v-icon left>tl-facebook</v-icon>
              Facebook
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col v-if="impressions.length" cols="12" md="5" lg="4">
        <v-card outlined height="100%">
          <v-list-item class="py-2">
            <v-list-item-avatar color="grey">
              <v-icon dark>tl-camera</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="headline" v-t="'gym.gallery'" />
          </v-list-item>
          <v-divider />
          <v-container fluid align-start>
            <v-row>
              <v-col
                v-for="img in impressions"
                :key="img.id"
                class="d-flex child-flex"
                cols="4"
                @click="openImgDialog(impressions, img)"
              >
                <v-card flat tile class="d-flex">
                  <v-img :src="`${img.url}?t=cover&w=400&h=400`" aspect-ratio="1" class="grey lighten-2">
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="12" md="5" lg="4">
        <v-card outlined>
          <v-list-item class="py-2">
            <v-list-item-avatar color="grey">
              <v-icon dark>tl-access-time</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="headline" v-t="'gym.hours'" />
          </v-list-item>
          <v-simple-table>
            <thead>
              <tr>
                <th />
                <th v-for="item in itemsToShow" :key="item" class="text-left">
                  {{ gym.opening_hours.items[item] }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="day in dayNames" :key="day">
                <td v-t="`gym.${day}`" />
                <td class="text-no-wrap" v-if="gym.opening_hours.days[day].closed" colspan="2" v-t="'gym.closed'" />
                <td class="text-no-wrap" v-else v-for="item in itemsToShow" :key="item">
                  {{ gym.opening_hours.days[day].items[item] }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col md="7" lg="12">
        <v-lazy>
          <v-card outlined>
            <v-list-item class="py-2">
              <v-list-item-avatar color="grey">
                <v-icon dark>tl-directions</v-icon>
              </v-list-item-avatar>
              <v-list-item-title class="headline" v-t="'gym.contact'" />
            </v-list-item>
            <v-card-text>
              <v-row>
                <v-col cols="12" lg="3">
                  <div class="body-1 text--primary">{{ gym.name }}</div>
                  <div>{{ gym.address }}</div>
                  <div>{{ gym.postal_code }} {{ gym.city }}</div>
                </v-col>
                <v-col cols="12" lg="3" v-if="gym.phone_number">
                  <div class="body-1 text--primary" v-t="'gym.phone'" />
                  <div>{{ gym.phone_number }}</div>
                </v-col>
                <v-col cols="12" lg="3" v-if="gym.parking">
                  <div class="body-1 text--primary" v-t="'gym.parking'" />
                  <div>{{ gym.parking }}</div>
                </v-col>
              </v-row>

              <tl-leaflet-map
                v-if="showMap"
                :center="[gym.latitude, gym.longitude]"
                :zoom="14"
                disable-gestures
                style="height: 300px"
              >
                <tl-leaflet-marker :lat-lng="[gym.latitude, gym.longitude]">
                  <l-tooltip>{{ gym.name }}</l-tooltip>
                </tl-leaflet-marker>
              </tl-leaflet-map>
            </v-card-text>
          </v-card>
        </v-lazy>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import toolbarMixin from '@/components/layout/toolbar/toolbar.mixin'
import GymResources from '@/models/GymResources'
import { mapState } from 'vuex'
import tlGradeDist from '@/components/shared/tl-grade-dist'
import tlLeafletMap from '@/components/shared/leaflet/tl-leaflet-map.vue'
import tlLeafletMarker from '@/components/shared/leaflet/tl-leaflet-marker.vue'
import { LTooltip } from 'vue2-leaflet'

export default {
  mixins: [toolbarMixin],
  components: {
    tlGradeDist,
    tlLeafletMap,
    tlLeafletMarker,
    LTooltip,
  },
  head() {
    let description = this.$t('gym.description', { gymName: this.gym.name })
    return {
      title: this.$t('gym.title', { gymName: this.gym.name }),
      meta: [{ vmid: 'description', name: 'description', content: description }],
    }
  },
  data: () => ({
    loadingResources: true,
  }),
  computed: {
    ...mapState(['gym']),
    tlToolbarTitle() {
      return this.$t('sidenav.myGym')
    },
    tlToolbarButtons() {
      return [
        {
          icon: 'tl-swap-horiz',
          text: this.$t('sidenav.switchGym'),
          action: this.switchGym,
        },
      ]
    },
    impressions() {
      return this.gym.gym_resources
        .filter(gr => gr.resource_type != 'main_impression')
        .slice()
        .sort((a, b) => a.order - b.order)
    },
    markers() {
      return [
        {
          label: this.gym.name,
          position: {
            lat: this.gym.latitude,
            lng: this.gym.longitude,
          },
        },
      ]
    },
    showMap() {
      return this.gym.latitude && this.gym.longitude
    },
    dayNames() {
      return ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
    },
    itemsToShow() {
      return [1, 2, 3, 4]
        .map(i => `item${i}`)
        .filter(item => {
          return this.dayNames.some(name => this.gym.opening_hours.days[name].items[item])
        })
    },
  },
  created() {
    this.fetchResources()
  },
  methods: {
    async fetchResources() {
      await GymResources.$apiAll({ params: { gym_id: this.gym.id } })
      this.loadingResources = false
    },
    switchGym() {
      this.$router.push({ name: 'gyms', params: {} })
    },
    openImgDialog(images, selected) {
      const component = () => import('@/components/shared/tl-img-dialog.vue')
      this.$store.dispatch('dialog/open', { component, props: { images, selected, maxWidth: 800 } })
    },
  },
}
</script>
